var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-end align-items-end"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Rechercher")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_value),expression:"search_value"}],staticClass:"form-control w-auto",attrs:{"type":"text"},domProps:{"value":(_vm.search_value)},on:{"keyup":function($event){return _vm.search(_vm.search_value)},"input":function($event){if($event.target.composing)return;_vm.search_value=$event.target.value}}})]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-plus-square"}),_vm._v(" Actualiser ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'customers-create' })}}},[_c('i',{staticClass:"bi bi-plus-square"}),_vm._v(" Créer un nouveau client ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.customers),function(customer,index){return _c('tr',{key:index++},[_c('td',{staticClass:"col-auto"},[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"col-2"},[_vm._v(" "+_vm._s(customer.reference)+" ")]),_c('td',{staticClass:"col-4"},[_vm._v(" "+_vm._s(customer.fullName)+" ")]),_c('td',{staticClass:"col-4"},[_vm._v(" "+_vm._s(customer.society)+" ")]),_c('td',{staticClass:"col-2"},[_vm._v(" "+_vm._s(customer.phone)+" ")]),_c('td',{staticClass:"col-3"},[(customer.type)?_c('span',[_c('button',{staticClass:"btn p-0 m-0",on:{"click":function($event){_vm.maasked_type == true
                  ? ((_vm.maasked_type = false), (_vm.display_type_customer = ''))
                  : ((_vm.maasked_type = true),
                    (_vm.display_type_customer = customer.reference))}}},[_c('i',{staticClass:"bi bi-eye fs-5"}),_vm._v(" "+_vm._s(_vm._f("masked")(customer.type.fullName, _vm.maasked_type, customer.reference, _vm.display_type_customer ))+" ")])]):_vm._e()]),_c('td',{staticClass:"col-2 d-flex"},[_c('button',{staticClass:"btn text-secondary",on:{"click":function($event){return _vm.$router.push({
                name: 'customers-details',
                params: { reference: customer.reference },
              })}}},[_c('i',{staticClass:"bi bi-plus"})]),_c('button',{staticClass:"btn text-secondary",on:{"click":function($event){return _vm.$router.push({
                name: 'customers-edit',
                params: { reference: customer.reference },
              })}}},[_c('i',{staticClass:"bi bi-pencil-square"})]),_c('button',{staticClass:"btn text-danger",on:{"click":function($event){return _vm.remove(customer)}}},[_c('i',{staticClass:"bi bi-trash"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col-auto"}},[_vm._v("Nº")]),_c('th',{attrs:{"scope":"col-2"}},[_vm._v("Référence Nº")]),_c('th',{attrs:{"scope":"col-4"}},[_vm._v("Nom Complet")]),_c('th',{attrs:{"scope":"col-4"}},[_vm._v("Nom Commercial")]),_c('th',{attrs:{"scope":"col-1"}},[_vm._v("Phone")]),_c('th',{attrs:{"scope":"col-3"}},[_vm._v("Type")]),_c('th',{attrs:{"scope":"col-3"}})])])
}]

export { render, staticRenderFns }